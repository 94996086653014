import React from "react";
import Home from "./page(s)";

function App() {
  return (
    <div className="h-screen max-w-[1920px] overflow-x-hidden mx-auto">
      <Home />
    </div>
  );
}

export default App;
